

$base-font: 'Quicksand', sans-serif;

$blue: #2484c6;
$base: #000000;
$white: #ffffff;

$light: 300;
$normal: 400;
$sbold: 600;
$bold: 700;
$xbold: 800;

